import Gen from "@helper/Gen"
var fo_router = [
    {
        name: "Index",
        path: "",
        component: () => import("./frontend/Index.vue"),
    },
    {
        name: "Products",
        path: "produk",
        component: () => import("./frontend/Products.vue"),
    },
    {
        name: "ProductsList",
        path: "list-produk/:slug",
        component: () => import("./frontend/ProductsList.vue"),
    },
    {
        name: "ProductsDetail",
        path: "produk/:slug",
        component: () => import("./frontend/ProductsDetail.vue"),
    },
    {
        name: "HealthInfo",
        path: "informasi-kesehatan",
        component: () => import("./frontend/HealthInfo.vue"),
    },
    {
        name: "HealthInfoDetail",
        path: "detail-informasi-kesehatan/:slug",
        component: () => import("./frontend/DetailHealthInfo.vue"),
    },
    {
        name: "HealthInfoDetailArticle",
        path: "detail-informasi-kesehatan/:slug/:sub",
        component: () => import("./frontend/DetailHealth.vue"),
    },
    {
        name: "DetailHealthArticle",
        path: "detail-kesehatan/:sub/:slug",
        component: () => import("./frontend/DetailHealthArticle.vue"),
    },
    {
        name: "Search",
        path: "search-result",
        component: () => import("./frontend/DetailHealth.vue"),
    },
    {
        name: "Resep",
        path: "resep/:cat?",
        component: () => import("./frontend/Resep.vue"),
    },
    {
        name: "DetailResep",
        path: "detail-resep/:slug",
        component: () => import("./frontend/DetailResep.vue"),
    },
    {
        name: "Event",
        path: "event",
        component: () => import("./frontend/Event.vue"),
    },
    {
        name: "DetailEvent",
        path: "detail-event/:slug",
        component: () => import("./frontend/DetailEvent.vue"),
    },
    {
        name: "TanyaDoctor",
        path: "tanya-dokter",
        component: () => import("./frontend/TanyaDoctor.vue"),
    },
    {
        name: "DetailPertanyaan",
        path: "detail-pertanyaan/:slug",
        component: () => import("./frontend/DetailPertayaan.vue"),
    },
    {
        name: "JawabPertanyaan",
        path: "jawab-pertanyaan/:code",
        component: () => import("./frontend/JawabPertanyaan.vue"),
    },
    {
        name: "TermOfUse",
        path: "terms-of-use",
        component: () => import("./frontend/Toc.vue"),
    },
    {
        name: "PrivacyPolicy",
        path: "privacy-policy",
        component: () => import("./frontend/Privacy.vue"),
    },
    {
        name: "404",
        path: "*",
        component: () => import("./frontend/Page404.vue"),
    }
]
fo_router.map((v) => {
	v.beforeEnter = (to, from, next) => {
		if (typeof $ != 'undefined') $("html,body").scrollTop(0)
		next()
	}
})
var data = [{
		name: "logout",
		path: "logout",
		beforeEnter(from, to, next) {
			Gen.delCookie("fotk")
			Gen.delStorage("fotk")
			if (typeof App != "undefined") App.user = null
			next({
				name: 'Home'
			})
		},
	},
	{
		path: "/",
		component: () => import('@/layout/FoLayout.vue'),
		children: fo_router
	},
]
export default data