import Gen from '@helper/Gen';
import BOGen from './helper/BOGen';

var bo_router = [{
        path: "dashboard",
        name: "BoDashboard",
        component: () =>
            import ("@backend/Dashboard.vue")
    },
    {
        path: "home/:id?",
        name: "BoHome",
        component: () =>
            import("@backend/BoHome.vue")
    },
    {
        path: "product-category/:id?",
        name: "BoProductCat",
        component: () =>
            import("@backend/BoProductCat.vue")
    },
    {
        path: "product/:id?",
        name: "BoProduct",
        component: () =>
            import("@backend/BoProduct.vue")
    },
    {
        path: "article/:id?",
        name: "BoTopic",
        component: () =>
            import("@backend/BoTopic.vue")
    },
    {
        path: "article-category/:id?",
        name: "BoTopicCat",
        component: () =>
            import("@backend/BoTopicCat.vue")
    },
    {
        path: "recipe/:id?",
        name: "BoRecipe",
        component: () =>
            import("@backend/BoRecipe.vue")
    },
    {
        path: "recipe-category/:id?",
        name: "BoRecipeCat",
        component: () =>
            import("@backend/BoRecipeCat.vue")
    },
    {
        path: "event/:id?",
        name: "BoEvent",
        component: () =>
            import("@backend/BoEvent.vue")
    },
    {
        path: "asked-doctor/:id?",
        name: "BoAskedDoctor",
        component: () =>
            import("@backend/BoAskedDoctor.vue")
    },
    {
        path: "seo/:id?",
        name: "StaticSeo",
        component: () =>
            import("@backend/BoStaticSeo.vue")
    },
    {
        path: "web-settings/:id?",
        name: "AppWebSettings",
        component: () =>
            import("@backend/AppWebSettings.vue")
    },
    {
        path: "static-content/:id?",
        name: "StaticContent",
        component: () =>
            import("@backend/StaticContent.vue")
    },
    {
        path: "master-template/:id?",
        name: "MrTemplate",
        component: () =>
            import("@backend/MrTemplate.vue")
    },
    {
        path: "article/:id?",
        name: "AppPubPost",
        component: () =>
            import("@backend/Dashboard.vue")
    },
    {
        path: "blog-category/:id?",
        name: "AppPubPostCat",
        component: () =>
            import("@backend/Dashboard.vue")
    },
    {
        path: "profile/:id?",
        name: "BoProfile",
        component: () =>
            import ("@backend/Profile.vue")
    },
    {
        path: "users/:id?",
        name: "BoUsers",
        component: () =>
            import ("@backend/Users.vue")
    },
    {
        path: "user-level/:id?",
        name: "BoUserLevel",
        component: () =>
            import ("@backend/UserLevel.vue")
    },
    {
        path: "food-meter/:id?",
        name: "BoFoodMeter",
        component: () =>
            import ("@backend/BoFoodMeter.vue")
    },
    {
        path: "pradiabetes/:id?",
        name: "BoPradiabetes",
        component: () =>
            import ("@backend/BoPradiabetes.vue")
    },
    {
        path: "heart-disease/:id?",
        name: "BoHeartDisease",
        component: () =>
            import ("@backend/BoHeartDisease.vue")
    },
    {
        path: "bmi/:id?",
        name: "BoBmi",
        component: () =>
            import ("@backend/BoBmi.vue")
    },
    {
        path: "activity/:id?",
        name: "BoActivityCounter",
        component: () =>
            import ("@backend/BoActivityCounter.vue")
    },
    // 404
    { path: '*', redirect: { name: "BoDashboard" } },
];

var menuBypassRole = ["BoProfile"]

bo_router.map((v) => {
    v.beforeEnter = (to, from, next) => {
        var user = BOGen.user()
        if (!user || !Gen.getStorage('botk')) return next({ name: "BoLogin" })
        if (menuBypassRole.indexOf(to.name) > -1) return next()
        if (user.bu_level == 0) return next()
        if (user.menuRole.indexOf(to.name) == -1) return next({ name: user.menuRole[0] })
        next()
    }
    v.meta = { bo_protected: true }
})

var data = [{
        name: "BoLogin",
        path: "login",
        component: () =>
            import ("@backend/Login.vue"),
        beforeEnter: (to, from, next) => {
            var user = BOGen.user()
            if (user && Gen.getStorage('botk')) return next({ name: "BoDashboard" })
            next()
        }
    },
    // FileManager
    {
        name: "FileManager",
        path: 'file-explore',
        component: () =>
            import ("@components/FileManagerPage.vue")
    },
    {
        path: "",
        component: () =>
            import ('@/layout/BoLayout.vue'),
        redirect: { name: 'BoDashboard' },
        children: bo_router
    },
]

export default data